<template>
  <div>
    <b-row>
      <b-col cols="10">
        <h1>System Messages</h1>
      </b-col>
      <b-col cols="3" class="mt-sm-3">
        <bot-selector @botChanged="(botId) => (formItem.botId = botId)" />
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="12" xl="8">
        <b-row>
          <b-col cols="12" class="p-3"></b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <system-message
              class="mb-5"
              :botId="formItem.botId"
              :system-messages="systemMessages"
            ></system-message>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <div v-if="isBusy" class="spinner-busy">
      <b-spinner class="align-middle"></b-spinner>
      <strong>Loading...</strong>
    </div>
  </div>
</template>

<script>
import httpclient from '@/mixins/HttpHandler';
import SystemMessage from '@/components/Intents/SystemMessage';
import BotSelector from '@/components/UI/BotSelector';

export default {
  mixins: [httpclient],
  components: {
    BotSelector,
    SystemMessage,
  },
  mounted() {
    const botId =
      Number(this.$route.params.id) || this.$store.state.selectedBotId;
    if (botId) this.formItem.botId = botId;
    // const vm = this;
    // const botId = Number(vm.$route.params.botId);
    //
    // vm.httpclient.get('/api/bots').then(function (resp) {
    //   vm.bots = resp.data;
    //
    //   if (botId) {
    //     vm.formItem = _.cloneDeep(
    //       _.assign({}, _.find(vm.bots, { botId: botId }))
    //     );
    //   } else {
    //     vm.formItem = _.cloneDeep(_.assign({}, vm.bots[0]));
    //   }
    // });
  },
  data() {
    return {
      path: 'system-messages',
      bots: [],
      formItem: {
        botId: -1,
      },
      languages: [
        { languageId: 'English', languageName: 'English' },
        { languageId: 'Chinese', languageName: 'Chinese' },
        { languageId: 'Vietnamese', languageName: 'Vietnamese' },
        { languageId: 'Thai', languageName: 'Thai' },
      ],
      selectedSystemMessage: {
        name: 'systemmessage',
        title: 'System Message',
        type: 'answer',
      },
      systemMessages: [
        { name: 'welcome', title: 'Welcome Message', type: 'answer' },
        { name: 'noanswer', title: 'No Answer', type: 'answer' },
        {
          name: 'requireauthentication',
          title: 'Require Authentication Message',
          type: 'answer',
        },
        {
          name: 'quickreplymessage',
          title: 'Contact Agent Button',
          type: 'text',
        },
        { name: 'no_respond', title: 'No Respond', type: 'text' },
        { name: 'leave_chat', title: 'Leave Chat', type: 'text' },
        { name: 'end_chat', title: 'End Chat', type: 'text' },
        {
          name: 'download_chat_history',
          title: 'Download Chat History',
          type: 'text',
        },
        { name: 'cancel_form', title: 'Cancel Form', type: 'text' },
        { name: 'network_problem', title: 'Network Problem', type: 'text' },
        {
          name: 'live_agent_init_fail',
          title: 'Live Agent Init Fail',
          type: 'text',
        },
        { name: 'live_agent_typing', title: 'Live Agent Typing', type: 'text' },
        {
          name: 'live_agent_conneting',
          title: 'Live Agent Connecting',
          type: 'text',
        },
        {
          name: 'live_agent_connected',
          title: 'Live Agent Connected',
          type: 'text',
        },
        { name: 'live_agent_joined', title: 'Live Agent Joined', type: 'text' },
        {
          name: 'live_agent_transferred',
          title: 'Live Agent Transferred',
          type: 'text',
        },
        { name: 'live_agent_ended', title: 'Live Agent Ended', type: 'text' },
        { name: 'leave_message', title: 'Leave Message', type: 'text' },
        {
          name: 'success_login__message',
          title: 'Success Login Message',
          type: 'text',
        },
        {
          name: 'connection_problem_login',
          title: 'Connection Problem Login',
          type: 'text',
        },
        { name: 'cancel_login', title: 'Cancel Login', type: 'text' },
        { name: 'rejoin_chat', title: 'Rejoin Chat', type: 'text' },
        { name: 'reconnect_chat', title: 'Reconnect Chat', type: 'text' },
        { name: 'typing_bar', title: 'Typing Bar', type: 'text' },
        { name: 'end_chat_header', title: 'End Chat Header', type: 'text' },
        {
          name: 'end_chat_confirm_button',
          title: 'End Chat Confirm Button',
          type: 'text',
        },
        {
          name: 'upload_file_button',
          title: 'Upload File Button',
          type: 'text',
        },
        {
          name: 'queue_greeting_message',
          title: 'Queue Greeting Message',
          type: 'text',
        },
        { name: 'queue_message', title: 'Queue Message', type: 'text' },
        {
          name: 'queue_offline_form_button',
          title: 'Queue Offline Form Button',
          type: 'text',
        },
        {
          name: 'timeout_warning_message',
          title: 'Timeout Warning Message',
          type: 'text',
        },
        {
          name: 'intent_answer_option',
          title: 'Intent Answer Option',
          type: 'text',
        },
        {
          name: 'force_close',
          title: 'Force Close',
          type: 'text',
        },
        {
          name: 'delay_response_alert',
          title: 'Delay Response Alert',
          type: 'text',
        },
        {
          name: 'error_response',
          title: 'Error Response',
          type: 'text',
        },
      ],
    };
  },
  watch: {},
  methods: {
    getSystemMessage(data) {
      this.selectedSystemMessage = data;
      this.modalSystemMessage = true;
    },
  },
};
</script>
